'use client';

import { useEffect } from 'react';

import { ErrorReporter } from '@/infra/monitoring/error-reporter';
import * as tracker from '@/infra/tracking/tracker';
import { ErrorComponent } from '@/presenters/components/common/Error';

interface ErrorBoundaryProps {
  error: Error;
  reset: () => void;
}

const Error = ({ error, reset }: ErrorBoundaryProps) => {
  useEffect(() => {
    tracker.onError500PageAccess();

    ErrorReporter.report(error);
  }, [error]);

  return (
    <div data-testid="error-500-page">
      <ErrorComponent
        reset={() => {
          tracker.onError500PageActionClick();
          reset();
        }}
      />
    </div>
  );
};

export default Error;

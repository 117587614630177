import { datadogRum } from '@datadog/browser-rum';

type LoggerFn = (error: unknown, context?: Record<string, any>) => void;

type IErrorReporter = Record<'report', LoggerFn>;

export const ErrorReporter: IErrorReporter = {
  report(error, context) {
    datadogRum.addError(buildError(error), context);
  },
};

/**
 * In a try/catch, the type of error is unknown.
 * This function will build an Error object from the unknown error.
 */
const buildError = (maybeError: unknown) => {
  if (!maybeError) return new Error('Unknown error');

  if (maybeError instanceof Error) return maybeError;

  if (typeof maybeError === 'string') return new Error(maybeError);

  try {
    return new Error(JSON.stringify(maybeError));
  } catch {
    return new Error(String(maybeError));
  }
};
